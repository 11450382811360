import { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom"
import { useMovie } from '../../hooks/useMovie'

// styles
import './Quantity.css'

export default function Quantity() {
  const [kidTickets, setKidTickets] = useState(0)
  const [adultTickets, setAdultTickets] = useState(0)
  const [seniorTickets, setSeniorTickets] = useState(0)
  const [proceed, setProceed] = useState(false)
  const history = useHistory()

  const { changeKidTickets, changeAdultTickets, changeSeniorTickets, name, poster, showtime } = useMovie()

  // const movie = { name: "Paddington 2", poster_url: "http://is5.mzstatic.com/image/thumb/Video118/v4/ab/82/d9/ab82d9b5-7799-68e1-70f1-92ae66845aee/source/100000x100000-999.jpg", showtime: "5:30 pm" }

  const handleClick = (age, type) => {
    if (age === 'kid' && type === 'minus' && kidTickets > 0) {
      setKidTickets(prevKidTickets => prevKidTickets - 1)
    }
    if (age === 'kid' && type === 'plus' && kidTickets < 99) {
      setKidTickets(prevKidTickets => prevKidTickets + 1)
    }
    if (age === 'adult' && type === 'minus' && adultTickets > 0) {
      setAdultTickets(prevAdultTickets => prevAdultTickets - 1)
    }
    if (age === 'adult' && type === 'plus' && adultTickets < 99) {
      setAdultTickets(prevAdultTickets => prevAdultTickets + 1)
    }
    if (age === 'senior' && type === 'minus' && seniorTickets > 0) {
      setSeniorTickets(prevSeniorTickets => prevSeniorTickets - 1)
    }
    if (age === 'senior' && type === 'plus' && seniorTickets < 99) {
      setSeniorTickets(prevSeniorTickets => prevSeniorTickets + 1)
    }
  }

  const handleConfirmClick = () => {
    changeKidTickets(kidTickets)
    changeAdultTickets(adultTickets)
    changeSeniorTickets(seniorTickets)
    history.push('/concessions-start')

  }

  useEffect(() => {
    if (kidTickets + adultTickets + seniorTickets > 0) {
      setProceed(true)
    }
  }, [kidTickets, seniorTickets, adultTickets])

  return (
    <div>
      <h1 className="text-accent-color">TICKETS</h1>
      <div className="movie-poster-mini">
        <img src={poster} alt={name} />
      </div>
      <h2 className="text-light-color">{name}</h2>
      <div className="spacer-small"></div>
      <h3 className="text-light-color">{showtime}</h3>
      <div className="text-dark-color tickets-container">
        <h3>Child (12 & under)</h3>
        <div className="quantity-container">
          <div className={`left-col${kidTickets === 0 ? " disabled" : ""}`}>
            <button onClick={() => handleClick('kid', 'minus')}><ion-icon name="remove-circle-outline"></ion-icon></button>
          </div>
          <div className="center-col">
            <span>{kidTickets}</span>
          </div>
          <div className={`right-col${kidTickets >= 99 ? " disabled" : ""}`}>
            <button onClick={() => handleClick('kid', 'plus')}><ion-icon name="add-circle-outline"></ion-icon></button>
          </div>
        </div>
        <h3>Adult</h3>
        <div className="quantity-container">
          <div className={`left-col${adultTickets === 0 ? " disabled" : ""}`}>
            <button onClick={() => handleClick('adult', 'minus')}><ion-icon name="remove-circle-outline"></ion-icon></button>
          </div>
          <div className="center-col">
            <span>{adultTickets}</span>
          </div>
          <div className={`right-col${adultTickets >= 99 ? " disabled" : ""}`}>
            <button onClick={() => handleClick('adult', 'plus')}><ion-icon name="add-circle-outline"></ion-icon></button>
          </div>
        </div>
        <h3>Senior (55+)</h3>
        <div className="quantity-container">
          <div className={`left-col${seniorTickets === 0 ? " disabled" : ""}`}>
            <button onClick={() => handleClick('senior', 'minus')}><ion-icon name="remove-circle-outline"></ion-icon></button>
          </div>
          <div className="center-col">
            <span>{seniorTickets}</span>
          </div>
          <div className={`right-col${seniorTickets >= 99 ? " disabled" : ""}`}>
            <button onClick={() => handleClick('senior', 'plus')}><ion-icon name="add-circle-outline" ></ion-icon></button>
          </div>
        </div>
        <button
          className="main-button tickets-button"
          style={{ visibility: (proceed ? 'visible' : 'hidden') }}
          onClick={handleConfirmClick}
        >Confirm Tickets</button>
      </div>
    </div>
  )
}
