import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';


// styles
import './App.css';

// pages & components
import Location from './pages/location/Location';
import Movies from './pages/movies/Movies';
import Showtimes from './pages/showtimes/Showtimes';
import Quantity from './pages/quantity/Quantity';
import OrderComplete from './pages/orderComplete/OrderComplete';
import ConcessionsStart from './pages/concessionsStart/ConcessionsStart';
import ConcessionsRepeat from './pages/concessionsRepeat/ConcessionsRepeat';
import ConcessionsName from './pages/concessionsName/ConcessionsName';
import Snacks from './pages/snacks/Snacks';
import Desserts from './pages/desserts/Desserts';
import Drinks from './pages/drinks/Drinks';
import Scanner from './pages/scanner/Scanner';
import ScannerTest from './pages/scanner/ScannerTest';
import Login from './pages/login/Login';
import Header from './components/Header';

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Header />
        <div className="main-container">
          <Switch>
            <Route exact path="/">
              {/* <Location /> */}
              <Movies />
            </Route>
            <Route path="/movies">
              <Movies />
            </Route>
            <Route path="/showtimes">
              <Showtimes />
            </Route>
            <Route path="/quantity">
              <Quantity />
            </Route>
            <Route path="/confirmation">
              <OrderComplete />
            </Route>
            <Route path="/concessions-start">
              <ConcessionsStart />
            </Route>
            <Route path="/concessions-repeat">
              <ConcessionsRepeat />
            </Route>
            <Route path="/concessions-name">
              <ConcessionsName />
            </Route>
            <Route path="/snacks">
              <Snacks />
            </Route>
            <Route path="/desserts">
              <Desserts />
            </Route>
            <Route path="/drinks">
              <Drinks />
            </Route>
            <Route path="/scanner">
              <Scanner />
            </Route>
            <Route path="/scannertest">
              <ScannerTest />
            </Route>
            <Route path="/login">
              {/* {user && <Redirect to="/admin" />}
              {!user && <Login />} */}
            </Route>
          </Switch>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
