import { useState, useEffect } from 'react'
import { useMovie } from '../../hooks/useMovie'
import { useFirestore } from '../../hooks/useFirestore'

// styles & images
import './OrderComplete.css'
import QRCode from '../../assets/qrcode.png'

export default function OrderComple() {
  const { name, poster, showtime, kidTickets, adultTickets, seniorTickets, concessions } = useMovie()
  const [haveConcessions, setHaveConcessions] = useState(false)
  const { updateDocument, response } = useFirestore('currentMovie')

  useEffect(() => {
    if (concessions.length > 0) {
      setHaveConcessions(true)
    }

    updateDocument({
      name,
      poster,
      showtime,
      kidTickets,
      adultTickets,
      seniorTickets,
      concessions
    }, 'currentMovie')
  }, [])


  const totalTickets = kidTickets + adultTickets + seniorTickets
  // const movie = { name: "Paddington 2", poster_url: "http://is5.mzstatic.com/image/thumb/Video118/v4/ab/82/d9/ab82d9b5-7799-68e1-70f1-92ae66845aee/source/100000x100000-999.jpg", showtime: "5:30 pm", tickets: 2}

  return (
    <div className='order-complete'>
      <h1 className="text-accent-color">ORDER CONFIRMED</h1>
      <div className="spacer"></div>
      <h2 className="text-light-color">{`Thank you for your purchase of ${totalTickets} ticket${(totalTickets === 1) ? '' : 's'} to`}</h2>
      <div className="movie-poster-mini">
        <img src={poster} alt={name} />
      </div>
      <h3 className="text-accent-color movie-title">{name}</h3>
      <div className="spacer-small"></div>
      <h4 className="text-light-color">{`We'll see you at ${showtime}`}</h4>
      {haveConcessions && (
        <>
          <div className="spacer-small"></div>
          <h4 className="text-light-color">{`and have your ${concessions.length > 1 ? `${concessions.length} ` : ''}concession order${concessions.length === 1 ? '' : 's'} ready.`}</h4>        
        </>
      )}
      <div className="spacer-large"></div>
      <h3 className="text-dark-color">Show at theater entrance:</h3>
      <div className="spacer"></div>
      <img src={QRCode} alt="QR Code" className="qr-code" />
    </div>
  )
}
