import { useMovie } from '../../hooks/useMovie'
import { useHistory, useLocation } from "react-router-dom"
import { useEffect } from 'react'
import { useCollection } from '../../hooks/useCollection'

export default function Scanner() {
  const { documents, error } = useCollection('currentMovie')
  const movieData = documents

  return (
    <div className="scanner">
        {movieData && movieData.map((movie, index) => (
          <div key={index}>
            <h1 className="text-accent-color confirmation-message">{`${(movie.kidTickets + movie.adultTickets + movie.seniorTickets) > 1 ? `${(movie.kidTickets + movie.adultTickets + movie.seniorTickets)} ` : ''}TICKET${(movie.kidTickets + movie.adultTickets + movie.seniorTickets) <= 1 ? '' : 's'} Confirmed`}</h1>
            <div className="confirmation">
              <ion-icon name="checkmark-circle-outline"></ion-icon>
            </div>
            <div className="spacer"></div>
            <h2 className="text-light-color">Enjoy</h2>
            {movie.name && <h1 className="text-accent-color">{movie.name}</h1>}
            {movie.showtime && <h3 className="text-light-color">{`at ${movie.showtime}`}</h3>}
            <div className="movie-poster-mini">
              {movie.name && movie.poster && <img src={movie.poster} alt={movie.name} />}
            </div>
            {movie.concessions.length > 0 && (
              <>
                <div className="spacer-large"></div>
                <hr />
                <div className="spacer-large"></div>
                <h2 className="text-accent-color">Concessions orders</h2>
                <div className="spacer"></div>
              </>
            )}
            {movie.concessions.length > 0 && movie.concessions.map((concessionOrder, index) => (
              <>
                <div className="concession-order" key={index}>
                  <h3 className="text-accent-color">{concessionOrder.name}</h3>
                  <div className="spacer"></div>
                  {concessionOrder.snack && (
                    <div className="order-row">
                      <div className="poster">
                        <img src={concessionOrder.snack.poster_url} alt={concessionOrder.snack.name} />
                      </div>
                      <p className="text-accent-color">{concessionOrder.snack.name}</p>
                    </div>
                  )}
                  {concessionOrder.dessert && (
                    <div className="order-row">
                      <div className="poster">
                        <img src={concessionOrder.dessert.poster_url} alt={concessionOrder.dessert.name} />
                      </div>
                      <p className="text-accent-color">{concessionOrder.dessert.name}</p>
                    </div>
                  )}
                  {concessionOrder.drink && (
                    <div className="order-row">
                      <div className="poster">
                        <img src={concessionOrder.drink.poster_url} alt={concessionOrder.drink.name} />
                      </div>
                      <p className="text-accent-color">{concessionOrder.drink.name}</p>
                    </div>
                  )}
                </div>
                {index != (movie.concessions.length - 1) && <div className="spacer"></div>}
              </>
            ))}
          </div>
        ))}
    </div>
  )
}
