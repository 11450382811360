import { useContext } from 'react'
import { MovieContext } from '../context/MovieContext'

export const useMovie = () => {
  const context = useContext(MovieContext)

  if (context === undefined) {
    new Error("useMovie() must be used inside a ThemeProvider")
  }

  return context
}