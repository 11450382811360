import { useHistory, useLocation } from "react-router-dom"
import { useCollection } from '../../hooks/useCollection'

export default function Desserts() {
  // const desserts = [
  //   { name: "Fruit Snacks", poster_url: "https://i5.walmartimages.com/asr/801f60f9-4106-47ce-bf9b-a846260f8cc3.7a2f999ac5823f56bd5140a360a403db.jpeg?odnHeight=612&odnWidth=612&odnBg=FFFFFF" },
  //   { name: "Nutty Buddy", poster_url: "https://i5.walmartimages.com/asr/2eea1737-53a2-4841-84da-b3e60df80da1_2.97f2f12c219f4743d2fa5048943d2fcd.jpeg?odnHeight=612&odnWidth=612&odnBg=FFFFFF" }
  // ]
  const { documents, error } = useCollection(
    'desserts',
    ["enabled", "==", true],
    ["name"]
  )
  const desserts = documents

  let location = useLocation();
  const name = location.state.name
  const snack = location.state.snack

  const history = useHistory()

  const handleClick = (dessert) => {
    history.push('/drinks', { name, snack, dessert })
  }

  return (
    <div className="desserts">
      <h1 className="text-accent-color">dessertS</h1>
      <div className="spacer"></div>
      <h2 className="text-light-color">{`Choose ${name}'s dessert:`}</h2>
      <div className="container-flex">
        {desserts && desserts.map((dessert) => (
          <div className="item" key={dessert.name}>
            <div className="movie-poster">
              <img src={dessert.poster_url} alt={dessert.name} onClick={() => handleClick(dessert)} />
            </div>
            <button className="main-button" onClick={() => handleClick(dessert)}>{dessert.name}</button>
          </div>
        ))}
      </div>
    </div>
  )
}
