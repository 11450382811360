import { useHistory, useLocation } from "react-router-dom"
import { useMovie } from '../../hooks/useMovie'
import { useCollection } from '../../hooks/useCollection'

export default function Drinks() {
  // const drinks = [
  //   { name: "Chocolate Milk", poster_url: "https://i5.walmartimages.com/asr/0b2bba81-1172-4550-8143-7277398d2700_3.f4762f11cc6b9c6f1e40253951c533f8.jpeg?odnHeight=612&odnWidth=612&odnBg=FFFFFF" },
  //   { name: "Strawberry Milk", poster_url: "https://i5.walmartimages.com/asr/1ef9bffd-cb67-42bf-9abf-4a2809127a71.c7db1bc8b6100e757ca05c867663599e.jpeg?odnHeight=612&odnWidth=612&odnBg=FFFFFF" },
  //   { name: "Water", poster_url: Water },
  //   { name: "Apple Juice", poster_url: AppleJuice }
  // ]
  const { documents, error } = useCollection(
    'drinks',
    ["enabled", "==", true],
    ["name"]
  )
  const drinks = documents

  let location = useLocation();
  const name = location.state.name
  const snack = location.state.snack
  const dessert = location.state.dessert
  const { changeConcessions } = useMovie()


  const history = useHistory()

  const handleClick = (drink) => {
    changeConcessions({ name, snack, dessert, drink })
    history.push('/concessions-repeat')
  }

  return (
    <div className="drinks">
      <h1 className="text-accent-color">DRINK</h1>
      <div className="spacer"></div>
      <h2 className="text-light-color">{`Choose ${name}'s drink:`}</h2>
      <div className="container-flex">
        {drinks && drinks.map((drink) => (
          <div className="item" key={drink.name}>
            <div className="movie-poster">
              <img src={drink.poster_url} alt={drink.name} onClick={() => handleClick(drink)} />
            </div>
            <button className="main-button" onClick={() => handleClick(drink)}>{drink.name}</button>
          </div>
        ))}
      </div>
    </div>
  )
}
