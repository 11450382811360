import { useMovie } from '../../hooks/useMovie'
import { useHistory, useLocation } from "react-router-dom"

export default function Showtimes() {
  let location = useLocation();
  const showtimes = location.state.showtimes

  const { changeShowtime, name, poster } = useMovie()
  const history = useHistory()

  const handleClick = (showtime) => {
    changeShowtime(showtime)
    history.push('/quantity')
  }

  return (
    <div>
      <h1 className="text-accent-color">SHOWTIMES</h1>
        <div className="movie-poster-mini">
          <img src={poster} alt={name} />
        </div>
        <h2 className="text-light-color">{name}</h2>
        <div>
          {showtimes.map((showtime, index) => (
            <div className="showtimes" key={index}>
              <div className={`spacer${((index === 0) ? '' : '-large')}`}></div>
              <button className="main-button" onClick={() => handleClick(showtime)}>{showtime}</button>
            </div>
          ))}
        </div>
    </div>
  )
}