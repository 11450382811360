import { createContext, useReducer } from 'react'

export const MovieContext = createContext()

const movieReducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_NAME':
      return { ...state, name: action.payload }
    case 'CHANGE_POSTER':
      return { ...state, poster: action.payload }
    case 'CHANGE_SHOWTIME':
      return { ...state, showtime: action.payload }
    case 'CHANGE_KID_TICKETS':
      return { ...state, kidTickets: action.payload }
    case 'CHANGE_ADULT_TICKETS':
      return { ...state, adultTickets: action.payload }
    case 'CHANGE_SENIOR_TICKETS':
      return { ...state, seniorTickets: action.payload }
    case 'CHANGE_CONCESSIONS':
      return { ...state, concessions: [...state.concessions, action.payload] }
    default:
      return state
  }
}

export function MovieProvider({ children }) {
  const [state, dispatch] = useReducer(movieReducer, {
    name: null,
    poster: null,
    showtime: null,
    kidTickets: 0,
    adultTickets: 0,
    seniorTickets: 0,
    concessions: []
  })

  const changeName = (name) => {
    dispatch({ type: 'CHANGE_NAME', payload: name })
  }

  const changePoster = (poster) => {
    dispatch({ type: 'CHANGE_POSTER', payload: poster })
  }

  const changeShowtime = (showtime) => {
    dispatch({ type: 'CHANGE_SHOWTIME', payload: showtime })
  }

  const changeKidTickets = (kidTickets) => {
    dispatch({ type: 'CHANGE_KID_TICKETS', payload: kidTickets })
  }

  const changeAdultTickets = (adultTickets) => {
    dispatch({ type: 'CHANGE_ADULT_TICKETS', payload: adultTickets })
  }

  const changeSeniorTickets = (seniorTickets) => {
    dispatch({ type: 'CHANGE_SENIOR_TICKETS', payload: seniorTickets })
  }

  const changeConcessions = (concessions) => {
    dispatch({ type: 'CHANGE_CONCESSIONS', payload: concessions })
  }
  
  return (
    <MovieContext.Provider value={{ ...state, changeName, changePoster, changeShowtime, changeKidTickets, changeAdultTickets, changeSeniorTickets, changeConcessions }}>
      {children}
    </MovieContext.Provider>
  )
}