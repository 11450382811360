import { useHistory, useLocation } from "react-router-dom"
import { useCollection } from '../../hooks/useCollection'

export default function Snacks() {
  // const snacks = [
  //   { name: "Popcorn", poster_url: "https://i5.walmartimages.com/asr/c7ee23bf-fbb1-4dd2-b9cd-8590060384f5_1.5e06b25d629d4516c9f204852d6a04eb.jpeg?odnHeight=612&odnWidth=612&odnBg=FFFFFF" },
  //   { name: "Carmel & Cheddar Popcorn", poster_url: "https://i5.walmartimages.com/asr/fd530e26-6606-4da3-be7b-3c3afaee3bcc.e8b4deeb31e54171457ff3afc3886176.jpeg?odnHeight=612&odnWidth=612&odnBg=FFFFFF" },
  //   { name: "Goldfish", poster_url: "https://i5.walmartimages.com/asr/a1ec048f-4608-4b7d-8a88-db6b6874d065.e70ee9a2a3450eb762570a958d5d49cb.jpeg?odnHeight=612&odnWidth=612&odnBg=FFFFFF" }
  // ]
  const { documents, error } = useCollection(
    'snacks',
    ["enabled", "==", true],
    ["name"]
  )
  const snacks = documents

  let location = useLocation();
  const name = location.state.name

  const history = useHistory()

  const handleClick = (snack) => {
    history.push('/desserts', { name, snack })
  }

  return (
    <div className="snacks">
      <h1 className="text-accent-color">SNACKS</h1>
      <div className="spacer"></div>
      <h2 className="text-light-color hello">{`Choose ${name}'s snack:`}</h2>
      <div className="container-flex">
        {snacks && snacks.map((snack) => (
          <div className="item" key={snack.name}>
            <div className="movie-poster">
              <img src={snack.poster_url} alt={snack.name} onClick={() => handleClick(snack)} />
            </div>
            <button className="main-button" onClick={() => handleClick(snack)}>{snack.name}</button>
          </div>
        ))}
      </div>
    </div>
  )
}
