import { useEffect, useState, useRef } from 'react'
import { projectFirestore } from '../firebase/config'

export const useDocument = (collection, doc_id) => {
  const [document, setDocument] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    let ref = projectFirestore.collection(collection).doc(doc_id)

    const unsubscribe = ref.onSnapshot((snapshot) => {
      // update state
      setDocument(snapshot.data())
      setError(null)
    }, (error) => {
      console.log(error)
      setError('could not fetch the data')
    })

    // unsubscribe on unmount
    return () => unsubscribe()

  }, [collection, doc_id])

  return { document, error }
}