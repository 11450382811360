import { useMovie } from '../../hooks/useMovie'
import { useHistory, useLocation } from "react-router-dom"
import { useEffect } from 'react'
import { useCollection } from '../../hooks/useCollection'

export default function Movies() {
  const { documents, error } = useCollection(
    'movies',
    ["enabled", "==", true],
    ["name"]
  )
  const movieData = documents
  // STATIC DATA
  // const movieData = [
  //   { name: "Paddington 2", poster_url: "http://is5.mzstatic.com/image/thumb/Video118/v4/ab/82/d9/ab82d9b5-7799-68e1-70f1-92ae66845aee/source/100000x100000-999.jpg", showtimes: ["5:30 pm", "7:00 pm"] },
  //   {name: "Ugly Dolls", poster_url: "http://is5.mzstatic.com/image/thumb/Video123/v4/9a/7d/e7/9a7de7e8-60a8-6c79-a346-0f2a2389bab0/source/100000x100000-999.jpg", showtimes: ["6 pm"]}
  // ]
  const { changeName, changePoster } = useMovie()
  const history = useHistory()
  let location = useLocation();


  const handleClick = (movie) => {
    changeName(movie.name)
    changePoster(movie.poster_url)
    history.push('/showtimes', { showtimes: movie.showtimes })
  }

  useEffect(() => {
    location.state = {}
  }, [])

  return (
    <div>
      <h1 className="text-accent-color">MOVIES</h1>
      <div className="container-flex">
        {movieData && movieData.map((movie) => (
          <div className="item" key={movie.name}>
            <div className="movie-poster">
              <img src={movie.poster_url} alt={movie.name} onClick={() => handleClick(movie)} />
            </div>
            <button className="main-button" onClick={() => handleClick(movie)}>{movie.name}</button>
          </div>
        ))}
      </div>
    </div>
  )
}
