import React from 'react'
import { Link } from 'react-router-dom'

// import styles & images
import './Header.css'
import Logo from '../assets/logo.jpg'

export default function Header() {
  return (
    <div className="logo-container">
      <Link to="/"><img src={Logo} alt="Shaida Family Movie Night" /></Link>
    </div>
  )
}
