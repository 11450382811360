import { useHistory } from "react-router-dom"

export default function ConcessionsRepeat() {
  const history = useHistory()

  const handleClick = (choice) => {
    if (choice === 'no') {
      history.push('/confirmation')
    } else if (choice === 'yes') {
      history.push('/concessions-name')
    }
  }

  return (
    <div className="concessions-repeat">
      <h1 className="text-accent-color">CONCESSION</h1>
      <div className="spacer"></div>
      <h2 className="text-light-color">Would anyone else in your group like concessions?</h2>
      <div className='spacer-large'>
        <button className="main-button concessions" onClick={() => handleClick('yes')}>YES</button>
      </div>
      <div className='spacer-large'>
        <button className="main-button secondary-button concessions" onClick={() => handleClick('no')}>NO</button>
      </div>
    </div>
  )
}
