import { useState, useEffect } from 'react'
import { useMovie } from '../../hooks/useMovie'
import { useDocument } from '../../hooks/useDocument'

// styles
import './Scanner.css'

export default function ScannerTest() {
  const { document, error } = useDocument('currentMovie', 'currentMovie')
  const currentMovie = document

  return (
    <>
      {/* <h3 className="text-accent-color">{`${totalTickets > 1 ? `${totalTickets} ` : ''}TICKET${totalTickets <= 1 ? '' : 's'} to`}</h3> */}
      {currentMovie.name && <h1 className="text-light-color">{currentMovie.name}</h1>}
      {currentMovie.showtime && <h3 className="text-accent-color">{`at ${currentMovie.showtime}`}</h3>}
      <div className="movie-poster-mini">
        {currentMovie.name && currentMovie.poster && <img src={currentMovie.poster} alt={currentMovie.name} />}
      </div>
      <div className="spacer"></div>
      <div className="confirmation">
        <ion-icon name="checkmark-circle-outline"></ion-icon>
      </div>
      <div className="spacer"></div>
      <h2 className="text-light-color">Enjoy the show!</h2>
      <hr />
      {currentMovie.concessions.length > 0 && (
        <>
          <h2>Concessions orders</h2>
          <div className="spacer-large"></div>
        </>
      )}
      {currentMovie.concessions.length > 0 && currentMovie.concessions.map((concessionOrder, index) => (
        <div className="concession-order" key={index}>
          <h3 className="text-accent-color">{concessionOrder.name}</h3>
          <div className="spacer"></div>
          {concessionOrder.snack && (
            <div className="order-row">
              <div className="poster">
                <img src={concessionOrder.snack.poster_url} alt={concessionOrder.snack.name} />
              </div>
              <p className="text-accent-color">{concessionOrder.snack.name}</p>
            </div>
          )}
          {concessionOrder.dessert && (
            <div className="order-row">
              <div className="poster">
                <img src={concessionOrder.dessert.poster_url} alt={concessionOrder.dessert.name} />
              </div>
              <p className="text-accent-color">{concessionOrder.dessert.name}</p>
            </div>
          )}
          {concessionOrder.drink && (
            <div className="order-row">
              <div className="poster">
                <img src={concessionOrder.drink.poster_url} alt={concessionOrder.drink.name} />
              </div>
              <p className="text-accent-color">{concessionOrder.drink.name}</p>
            </div>
          )}
        </div>
      ))}
    </>
  )
}
