import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyAsnnOwhuMeeavsYF9F37Gz_ggVSyaiQLw",
  authDomain: "family-movie-night-7b140.firebaseapp.com",
  projectId: "family-movie-night-7b140",
  storageBucket: "family-movie-night-7b140.appspot.com",
  messagingSenderId: "159636516555",
  appId: "1:159636516555:web:e94bf98f305b7bbc77f54e"
};

// init firebase
firebase.initializeApp(firebaseConfig)

// init service
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()

// timestamp
const timestamp = firebase.firestore.Timestamp

export { projectFirestore, projectAuth, timestamp }