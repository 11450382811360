import { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"

// styles
import './ConcessionsName.css'

export default function ConcessionsName() {
  const [name, setName] = useState('')
  const history = useHistory()
  let location = useLocation();


  const handleSubmit = (e) => {
    e.preventDefault()
    history.push('/snacks', { name })
  }

  useEffect(() => {
    location.state = {}
  }, [])

  return (
    <div className="concessions-name">
      <h1 className="text-accent-color">CONCESSION</h1>
      <div className="spacer"></div>
      <h2 className="text-light-color">Enter name for concessions order:</h2>
      <div className="spacer-large"></div>
      <form className="concessions-form" onSubmit={handleSubmit}>
        <input
          type="text"
          onChange={(e) => setName(e.target.value)}
          value={name}
          required
          autoFocus
          placeholder='Name'
        />
        <div className="spacer-large"></div>
        <button className='main-button concessions'>NEXT</button>
      </form>
    </div>
  )
}
